import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { AboutUs } from "./pages/aboutus";
import { Faq } from "./pages/faq";
import { Home } from "./pages/home";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import { AnimatePresence } from "framer-motion";

Amplify.configure(config);
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  document.title = "Canyon Book Collection";
  const query = useQuery();
  const location = useLocation();
  let language = query.get("language")? query.get("language"): "en";


  return (
    <>
      <Navbar />
      <div className="mt-20 overflow-hidden">
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route path="/aboutus">
              <AboutUs language={language} />
            </Route>
            <Route path="/faq" language={language}>
              <Faq />
            </Route>
            <Route path="/">
              <Home language={language} />
            </Route>
          </Switch>
        </AnimatePresence>
      </div>
    </>
  );
}

export default App;
