import React from 'react';
import VideoEmbed from './videoEmbed';
import BackgroundImage from './../assets/svg/Liquid-Cheese2.svg'


function VideoSection(props) {
    const { language } = props;
    let sectionStyle = { backgroundImage: `url(${BackgroundImage})`, backgroundColor: "#ffff", backgroundSize: "cover" };
    return (
        <div className="flex flex-col justify-center items-center px-3 text-center pb-20 pt-40 h-full sm:h-ful md:h-full lg:h-full pt-3 md:px-10 md:pt-3" style={sectionStyle}>
            <div className="text-center">
                <h1 className="text-4xl mb-10 font-extrabold sm:text-3xl md:text-4xl pt-10 text-gray-900 text-shadow-md">Some of our visits</h1>
            </div>
            <div>
                <VideoEmbed embedId="_5wF03nt8co" />
            </div>

        </div>
    )
}

export default VideoSection;