import React from "react";
// import logot from "./../assets/image/others/logob.png";
import logo from "./../assets/image/logo/logo5.png";

class Footer extends React.Component {
  render() {
    // const { content } = this.props;
    return (
      <div className="flex flex-col items-center sm:flex-row  justify-between text-center w-100 bg-primary-900 pb-7">
        <div className="w-full sm:w-4/12">
        <img
              className="mx-auto bg-gray-700border p-5 max-h-28 my-auto"
              src={logo}
              alt="logo"
            />
        </div>
        <div className="w-full sm:w-4/12 p-2 text-gray-50 text-sm text-center sm:text-left m-3">
          All rights reserved. © 2021 TheCanyonBook <br />
          <p className="font-bold">Alwayssmile Online Ltd.</p>
          <p className="font-thin"> 38 Pickardclose N146JG, London<br/>
          </p>
          <div className="h-10 w-10 mt-2 mx-auto sm:mx-1">
          <a href="https://www.facebook.com/Free-Book-Collection-from-your-Home-106418781034251/" className="text-gray-100 hover:text-secondary-gray-50 hover:translate-y-1 transform hover:scale-110 hover:shadow-md h-10 w-10">
                <svg className="fill-current" viewBox="0 0 20 20">
                  <path
                    d="M17.675,0.62H2.327c-0.942,0-1.706,0.764-1.706,1.706v15.348c0,0.941,0.764,1.705,1.706,1.705h15.348c0.941,0,1.705-0.764,1.705-1.705V2.326C19.38,1.384,18.616,0.62,17.675,0.62 M18.526,17.674c0,0.471-0.381,0.852-0.852,0.852H2.327c-0.471,0-0.853-0.381-0.853-0.852V2.326c0-0.471,0.382-0.853,0.853-0.853h15.348c0.471,0,0.852,0.382,0.852,0.853V17.674zM10.849,7.975c0-0.345,0.035-0.531,0.565-0.531h0.709V6.162h-1.134c-1.364,0-1.844,0.642-1.844,1.721v0.834h-0.85V10h0.85v3.838h1.701V10h1.134l0.151-1.283h-1.285L10.849,7.975z"
                  ></path>
                </svg>
                </a>
          
          </div>
          
        </div>
        <div className="w-full flex flex-row items-center  justify-center sm:w-4/12 m-3">
          {/* <h1 className="block text-sm w-20  font-light text-gray-50 border-r-2 pr-2 m-2"> {content.quota1}</h1>
           <span className="block m-2">
           <a href="www.timetohelp.eu"> <img
              className="mx-auto w-20 h-20"
              src={logot}
              alt="timeToHelp"
            />
            </a>
          </span> */}
        </div>
      </div>
    );
  }
}

export default Footer;
