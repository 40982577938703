import React from 'react';

function ApprovalDialog(props) {
  const { showModal, setShowModal, order , submitForm, price } = props;

  const modelStyle = {
    position: "absolute",
    padding: "3% 3%",
    transform: "translate(-50 %, -50 %)",
    marginTop: "300px"
  }

  const handleSubmitForm = () => {
    submitForm();
    setShowModal(false);
  }

  return (<>
    {showModal ? (
      <>
        <div style={modelStyle}
          className="z-50 outline-none focus:outline-none w-full md:w-2/3 lg-1/2 xl-1/3 2xl-1/4"
        >
          <div>
            {/*content*/}
            <div className=" rounded-lg shadow-lg 
                relative
                flex flex-col bg-white outline-none 
                focus:outline-none bg-primary-200 border-primary-900 border-2">
              {/*header*/}
              <div className="flex items-start justify-between p-10 font-extrabold text-secondary-900">
                <h3 className="text-2xl md:text-xl font-extrabold text-secondary-900">
                </h3>
                <button
                  className="ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="text-white h-6 w-6 text-5xl block outline-none focus:outline-none" style={{ marginTop: "-40px" }}>
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="pl-3 flex flex-col text-sm">
                <div className='font-extrabold text-md'>Order details</div>
                <div className='ml-4 mr-4'>
                  <span className='font-bold'>Priority service:</span><br />
                  Pick up appointment will be arranged within maximum of 7 days.
                </div>
                <div className='m-4'>
                  <span className='font-bold'>Packing service:</span><br />
                  Packing service is limited to 700 books. Please contact to us for more books.
                </div>
                <div className='m-4'>
                  <span className='font-bold'>Payment method: </span><br />
                  On service delivery. Cash and credit card are possible.  <br />
                  The bill will be sent to your email as soon as you submit this form.
                </div>
                <div className='text-xl mt-2 pl-4 mb-4'>
                  <span className='font-bold mb-2'>Please make sure the following information is correct. </span><br />
                  <div className='flex flex-col pl-4'>
                  <div><span className='font-bold w-40 inline-block'>Name</span><span>{order?.name}</span></div>
                  <div><span className='font-bold w-40 inline-block'>Email</span><span>{order?.email}</span></div>
                  <div><span className='font-bold w-40 inline-block'>Phone</span><span>{order?.phone}</span></div>
                  <div><span className='font-bold w-40 inline-block'>Postcode</span><span>{order?.postcode}</span></div>
                  <div><span className='font-bold w-40 inline-block'>Number of books</span><span>{order?.numOfBooks}</span></div>
                  <div>
                    <span className='font-bold w-40 inline-block'>Priority service</span>
                    <span className={order?.fastDelivery ? 'text-green-500': 'text-red-500'}>
                      {order?.fastDelivery? "requested": "not requested"}
                      </span>
                  </div>
                  <div>
                    <span className='font-bold w-40 inline-block'>Packing service</span>
                    <span className={order?.packing ? 'text-green-500': 'text-red-500'}>
                      {order?.packing? "requested": "not requested"}
                      </span>
                  </div>
                  <div><span className='font-bold w-40 inline-block'>Total amount: </span><span>{price} £</span></div>

                  </div>
                  
                  
                </div>
              </div>
              {/*footer*/}
              <div className='border flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600'>
                <button onClick={() => handleSubmitForm()}type="button" className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>I accept</button>
                <button onClick={() => setShowModal(false)} type="button" className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'>Decline</button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
  ) : null
}
  </>);
}

export default ApprovalDialog;