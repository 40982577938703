import React, { useEffect, useState } from "react";
import { Hero } from "../components/hero";
import Feature from "../components/feature";
import ContactForm from "../components/contactform";
import Toplink from "../components/toplink";
import { pageTransitionVariants } from "../settings.js";
import { motion } from "framer-motion";
import { API } from "aws-amplify";
import Footer from "../components/footer";
import { siteContent } from "./../content/siteContent";
import PriorityModal from "../components/priorityModalElement";
import VideoSection from "../components/videoSection";




export function Home(props) {
  const [state, setState] = useState({ hasSent: undefined, error: "", language: "en" });
  const { language, hasSent, error } = state;

  useEffect(() => {
      let isContactFormLink = window.location.href.includes("contactform");
      setState(prev => ({ ...prev, language: props.language ? props.language : "en", isContactFormLink }))
    
      if (state.language !== props.language) {
        setState(prev => ({ ...state, language: props.language }))
      }
    // eslint-disable-next-line
  }, [])

  const handleFormSubmit = (form) => {
    setState(prev => ({ ...prev, hasSent: "inProgress" }));

    API.post("sheduleForm", "/submitForm", {
      body: form,
    })
      .then((response) => {
        setState(prev => ({ ...prev, hasSent: "sent" }))
        console.log(response);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setState(prev => ({ ...prev, error: error.message }));
      })
  }

  return (
    <>
      <motion.div
        className="flex flex-col"
        id="top"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageTransitionVariants}
        transition={pageTransitionVariants.transition}
      >
        <div id="home"></div>

        {!state.isContactFormLink && <PriorityModal />}
        <Hero
          content={siteContent[`${language}`].home.hero}
          language={language}
        />
        <div id="feature">
          <Feature content={siteContent[`${language}`].home.feature} />
        </div>
        <div id="video">
          <VideoSection />
        </div>
        <div id="contactform" >
          <ContactForm
            onSubmit={handleFormSubmit}
            hasSent={hasSent}
            error={error}
            content={siteContent[`${language}`].home.contactus}
          />
        </div>
      </motion.div>
      <Toplink language={language} />
      <Footer content={siteContent[`${language}`].home.footer} />
    </>
  );
}
